/* global.css */

body {
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  color: #037958; /* Use accent color for headings */
}

.container {
  padding: 0 5%;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  color: #037958;
}

/* Navbar */
nav {
  background-color: white;
  color: #037958;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Add a wrapper/container for proper width control */
.nav-container {
  width: 100%;
  max-width: 1200px; /* Limit the width of the nav for larger screens */
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

nav ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

nav ul li {
  margin-left: 1.5rem;
}

nav ul li a {
  color: #037958;
  font-size: 1.2rem;
  transition: color 0.3s ease-in-out;
}

nav ul li a:hover {
  color: #026647;
}

/* Responsive Behavior */
@media (max-width: 768px) {
  nav ul {
    flex-direction: column; /* Stack items vertically on small screens */
    align-items: center;
  }

  nav ul li {
    margin: 10px 0; /* Add margin between items in the vertical layout */
  }
}

body {
  padding-top: 70px; /* Adjust this based on your navbar height */
}

/* Button */
button {
  background-color: #037958;
  color: white;
  border: none;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  border-radius: 5px;
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
}

button:hover {
  background-color: #026647;
}

/* Hero Section */
.hero {
  background-color: white; /* White background */
  color: #037958; /* Accent color for text */
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem;
}

.hero h1 {
  font-size: 3rem;
  margin-bottom: 1.5rem;
}

.hero p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.hero button {
  font-size: 1.2rem;
}

.section {
  padding: 5rem 0;
  text-align: center;
  background-color: white; /* Set the background color to ensure visibility */
}

.section h2 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  color: #037958; /* Use your main theme color */
}

.section-content {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap; /* Wraps the items if there is insufficient space */
  gap: 2rem;
  max-width: 1200px; /* Set a max-width for proper content alignment */
  margin: 0 auto; /* Center the content */
}

.section-content div {
  flex-basis: 30%; /* Ensure each div takes up appropriate space */
  padding: 1rem;
  background-color: #f0f4f8;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.section-content h3 {
  color: #04376e;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.section-content p {
  font-size: 1rem;
  color: #666;
}

/* Footer */
footer {
  background-color: #037958; /* Accent color */
  color: white;
  text-align: center;
  padding: 1.5rem 0;
  font-size: 0.9rem;
}

button,
a {
  transition: all 0.3s ease;
}

button:hover {
  background-color: #026647;
  transform: translateY(-2px);
}

/* Fade-in sections as you scroll */
.section {
  opacity: 0;
  transform: translateY(30px);
  transition: all 0.6s ease;
}

.section.in-view {
  opacity: 1;
  transform: translateY(0);
}

/* Responsive */
@media (max-width: 768px) {
  .section-content {
    flex-direction: column;
    gap: 1rem;
  }

  .hero h1 {
    font-size: 2rem;
  }

  nav ul {
    flex-direction: column;
    align-items: center;
  }
}
